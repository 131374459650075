@use '../../../scss/abstracts' as *;
@use '../../../scss/layout' as *;

.jumbotron {
	padding: em(89) 0px;
	background-size: cover;
	background-repeat: no-repeat;
	@include full-width();
	background-color: $clr-neutral-6;
	background-position: center;

	@include breakpoint-min(md) {
		padding-top: em(170);
		padding-bottom: em(170);
	}
}
