/* Sass Variables */

$clr-primary: #5ab6f8;
$clr-secondary: #3e8bf7;
$clr-tertiary: #9757d7;
$clr-success: #45b26b;
$clr-danger: #ef466f;
$clr-warning: #ffd166;
$clr-neutral-2: #23262f;
$clr-neutral-3: #353945;
$clr-neutral-4: #777e90;
$clr-neutral-5: #b1b5c3;
$clr-lightGray: #f4f4f4;
$clr-neutral-6: #e6e8ec;
$clr-neutral-7: #f4f5f6;
$clr-white: #fcfcfd;

// white filter
$white-filter: brightness(0) saturate(100%) invert(99%) sepia(100%) saturate(2%) hue-rotate(127deg) brightness(109%)
    contrast(101%);

// icons
$primary-filter: brightness(0) saturate(100%) invert(60%) sepia(17%) saturate(7053%) hue-rotate(182deg) brightness(104%)
    contrast(94%);

// info icon
$secondary-filter: brightness(0) saturate(100%) invert(44%) sepia(95%) saturate(643%) hue-rotate(187deg) brightness(96%)
    contrast(103%);

// alerts
$danger-filter: brightness(0) saturate(100%) invert(48%) sepia(98%) saturate(3772%) hue-rotate(321deg) brightness(98%)
    contrast(92%);

// icons
$black-filter: brightness(0) saturate(100%) invert(0%) sepia(0%) saturate(7453%) hue-rotate(202deg) brightness(93%)
    contrast(93%);
