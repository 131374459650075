@use "../../../scss/abstracts" as *;

.modalbase_body {
    padding: 32px 24px;
    width: 100%;
    height: 100vh;
    max-width: 569px;
    max-height: 715px;
}
.modalbase_heading {
    width: 100%;
    font-family: $font-body;
    font-weight: 600;
    font-size: $body-1;
    margin-bottom: 0 !important;
    color: #141416;
    white-space: wrap;
}
.flipModalClose {
    position: absolute;
    bottom: 24px;
    right: 24px;
}
