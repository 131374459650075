@use '../abstracts' as *;
@use '../theme/colors' as *;
@use '../theme/borders' as *;

.table {
	border-collapse: inherit;
	border-spacing: 0px;

	th,
	td {
		border-right: border();

		&:first-child {
			border-left: border();
		}
	}
	> *,
	th {
		font-size: $small;
		text-align: center;
		font-weight: $fw-normal;
	}

	thead {
		th {
			font-weight: $fw-medium;
			background-color: $clr-neutral-7;
			border-top: border();

			&:first-of-type {
				border-top-left-radius: $border-radius;
			}

			&:last-of-type {
				border-top-right-radius: $border-radius;
			}
		}
	}

	tbody {
		th,
		td {
			vertical-align: middle;
			white-space: nowrap;
		}
	}
}
