@use '../../../scss/abstracts' as *;
@use '../../../scss/layout' as *;

#timer-container {
	display: none;
	order: 5;

	#timer {
		font-weight: $fw-medium;
		font-size: $small;
		@extend %icon-text-box;
		line-height: 0;
		gap: $gap-sm;
		&::before {
			content: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.99984 16.6666C13.6817 16.6666 16.6665 13.6819 16.6665 9.99996C16.6665 6.31806 13.6817 3.33329 9.99984 3.33329C6.31794 3.33329 3.33317 6.31806 3.33317 9.99996C3.33317 13.6819 6.31794 16.6666 9.99984 16.6666ZM9.99984 18.3333C14.6022 18.3333 18.3332 14.6023 18.3332 9.99996C18.3332 5.39759 14.6022 1.66663 9.99984 1.66663C5.39746 1.66663 1.6665 5.39759 1.6665 9.99996C1.6665 14.6023 5.39746 18.3333 9.99984 18.3333Z' fill='%23777E91'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.99984 5C10.4601 5 10.8332 5.3731 10.8332 5.83333V9.65482L12.6724 11.4941C12.9979 11.8195 12.9979 12.3472 12.6724 12.6726C12.347 12.998 11.8194 12.998 11.4939 12.6726L9.41058 10.5893C9.2543 10.433 9.1665 10.221 9.1665 10V5.83333C9.1665 5.3731 9.5396 5 9.99984 5Z' fill='%23777E91'/%3E%3C/svg%3E%0A");
		}
	}
}
