@use '../../../../../scss/abstracts' as *;
@use '../../../../../scss/layout/spacing' as *;
@use '../../../../../scss/theme/colors' as *;
@use '../../../../../scss/theme/borders' as *;

.left-col {
	.ticket-details {
		border-bottom: border();
		margin-bottom: rem(23);
	}

	#ticket-info {
		padding-bottom: rem(23);

		#ticket-counter {
			gap: $gap-xs;

			.btn--icon {
				border: none;
				border-radius: $border-radius-sm;
				height: 44px;

				svg {
					fill: $body-color;
				}
				&:disabled {
					svg {
						fill: $gray-400;
					}
				}
			}

			#ticket-count {
				display: flex;
				justify-content: center;
				align-items: center;
				padding: rem(10);
				border: border($border-width: 2px);
				background-color: $white;
				border-radius: $border-radius-sm;
				width: 40px;
				height: 44px;
			}
		}
	}
}
