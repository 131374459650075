@use "../abstracts" as *;
@use './colors' as *;
@use './borders' as *;

// Buttons
$btn-font-family: $font-heading;
$btn-font-weight: $fw-bold;
$btn-font-size: rem(14);

// medium button
$btn-font-size-lg: rem(16);
$btn-padding-y-lg: rem(14);
$btn-padding-x-lg: rem(24);

// small button
$btn-padding-x: rem(16);
$btn-padding-y: rem(10);

// xsmall button
$btn-padding-y-sm: rem(7);
$btn-padding-x-sm: rem(11);

$btn-border-radius: $border-radius-lg;
$btn-border-radius-sm: $border-radius-lg;
$btn-border-width: $border-width;
$btn-line-height: 1;
$btn-box-shadow: none;
$btn-focus-box-shadow: none;

// Close Button: Modals, Cards
$btn-close-bg: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z' fill='%23777E91'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.29289 8.29289C8.68342 7.90237 9.31658 7.90237 9.70711 8.29289L12 10.5858L14.2929 8.29289C14.6834 7.90237 15.3166 7.90237 15.7071 8.29289C16.0976 8.68342 16.0976 9.31658 15.7071 9.70711L13.4142 12L15.7071 14.2929C16.0976 14.6834 16.0976 15.3166 15.7071 15.7071C15.3166 16.0976 14.6834 16.0976 14.2929 15.7071L12 13.4142L9.70711 15.7071C9.31658 16.0976 8.68342 16.0976 8.29289 15.7071C7.90237 15.3166 7.90237 14.6834 8.29289 14.2929L10.5858 12L8.29289 9.70711C7.90237 9.31658 7.90237 8.68342 8.29289 8.29289Z' fill='%23777E91'/%3E%3C/svg%3E%0A");
$btn-close-width: 24px;

$navbar-light-toggler-icon-bg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4 16.5C4 15.9477 4.44772 15.5 5 15.5H19C19.5523 15.5 20 15.9477 20 16.5C20 17.0523 19.5523 17.5 19 17.5H5C4.44772 17.5 4 17.0523 4 16.5Z' fill='%23777E91'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4 8.5C4 7.94772 4.44772 7.5 5 7.5H19C19.5523 7.5 20 7.94772 20 8.5C20 9.05228 19.5523 9.5 19 9.5H5C4.44772 9.5 4 9.05228 4 8.5Z' fill='%23777E91'/%3E%3C/svg%3E");
$navbar-light-toggler-border-color: transparent;
