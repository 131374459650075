@use '../../../../../scss/abstracts' as *;
@use '../../../../../scss/layout/spacing' as *;

.left-col {
	.total-card {
		z-index: 3;
		border-bottom-right-radius: 0px;
		border-bottom-left-radius: 0px;
		box-shadow: $box-shadow-popupCard;

		&:not(.card--popup-expanded) {
			// margin-top: $text-spacer-xs;
		}

		.heading--flex {
			margin-top: 0px !important;
		}

		&.card--popup-expanded {
			position: absolute; // span entire page if needed

			.card-body {
				padding-bottom: 0px;
			}

			.card-footer {
				border-top-width: 0px;
			}
		}
	}
}
