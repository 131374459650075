@use "../../../../scss/abstracts" as *;
@use "../../../../scss/layout/container" as *;
@use "../../../../scss/layout/spacing" as *;
@use "../../../../scss/layout/images" as *;
@use "../../../../scss/theme/borders" as *;
@use "../../../../scss/theme/colors" as *;
@use "../../../../scss/theme/input" as *;

.map {
    display: flex;
    flex: 1;
    background-color: $gray-300;
    background-size: cover;
    @extend %img-background;
    position: relative;
    overflow: hidden;

    @include breakpoint-max(md) {
        @include full-width();
    }

    @include breakpoint-min(md) {
        border-top-right-radius: $border-radius;
        border-top-left-radius: $border-radius;
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: rgba(255, 255, 255, 0.2);
    }

    .with-blur-backdrop {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        -webkit-backdrop-filter: blur(50px);
        backdrop-filter: blur(50px);
    }

    // styles for popup to show on mobile
    &.full-height-mobile {
        @include breakpoint-max(md) {
            @include full-width();
            border: none;
            position: absolute;
            top: 0;
            bottom: 0;
            z-index: 1;
            padding-bottom: em(70);
        }
    }

    &.set-height {
        @include breakpoint-max(md) {
            max-height: 166px;
        }

        img {
            @include breakpoint-max(md) {
                width: 128px;
                height: 128px;
            }
        }
    }

    .react-transform-component {
        width: 100% !important;
        height: 100% !important;
    }

    .react-transform-wrapper {
        width: 100% !important;
        height: 100% !important;
    }

    .success {
        fill: #ffffff;
    }

    .htmlTooltip {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        isolation: isolate;
        position: absolute;
        width: 240px;
        min-height: 204px;
        background: #23262f;
        box-shadow: 0px 4px 64px rgba(0, 0, 0, 0.11);
        border-radius: $br-md;
        z-index: 11;
        .htmlTop {
            width: 100%;
            height: 32px;
            background-color: #353945;
            border-radius: $br-md $br-md 0px 0px;
            color: $white;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .htmlBody {
            flex-grow: 1;
            padding: $text-spacer-xs;
            padding-top: 0px;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
        }
        .htmlPrice {
            color: #ffffff;
            font-weight: $fw-semi-bold;
            font-size: 24px;
            margin-bottom: rem(10);
            margin-top: rem(10);
        }
        .htmlRow {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 0px rem(16);
            width: 100%;
            margin-bottom: rem(16);
            .htmlSection {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                gap: $gap-xs;
                height: 100%;
                width: 55px;
                .htmlPh {
                    color: #b1b5c4;
                    font-weight: $fw-normal;
                    font-size: $small;
                    line-height: 20px;
                }
                .htmlP {
                    color: $white;
                    font-weight: $fw-semi-bold;
                    font-size: $body-2;
                    line-height: 24px;
                }
            }
        }
        .htmlButton {
            display: block;
            align-items: center;
            width: 100%;
            background-color: #3ea9f7;
            border: none;
            outline: none;
        }
    }
    // Little rusty on my scss, be my guest w this one :)
    .htmlTooltip {
        &.top--center::after {
            content: " ";
            position: absolute;
            margin-left: -20px;
            border-style: solid;
            border-width: 20px;
            border-color: transparent transparent #23262f transparent;
            pointer-events: none;
            top: 100%;
            left: 50%;
            transform: rotate(180deg);
        }
        &.top--left::after {
            content: " ";
            position: absolute;
            margin-left: -20px;
            border-width: 20px;
            border-style: solid;
            pointer-events: none;
            border-color: transparent transparent #000 transparent;
            transform: rotate(135deg);
            top: calc(100% - 20px);
            left: 100%;
        }
        &.top--right::after {
            content: " ";
            position: absolute;
            margin-left: -20px;
            border-style: solid;
            pointer-events: none;
            border-width: 20px;
            border-color: transparent transparent #000 transparent;
            transform: rotate(-135deg);
            top: calc(100% - 20px);
            left: 0px;
        }
        &.bottom--center::after {
            content: " ";
            position: absolute;
            margin-left: -20px;
            border-width: 20px;
            border-style: solid;
            pointer-events: none;
            border-color: transparent transparent #353945 transparent;
            transform: rotate(0deg);
            top: -40px;
            left: 50%;
        }
        &.bottom--left::after {
            content: " ";
            position: absolute;
            margin-left: -20px;
            border-width: 20px;
            border-style: solid;
            pointer-events: none;
            border-color: transparent transparent #000 transparent;
            transform: rotate(45deg);
            top: -20px;
            left: 100%;
        }
        &.bottom--right::after {
            content: " ";
            position: absolute;
            margin-left: -20px;
            border-width: 20px;
            border-style: solid;
            pointer-events: none;
            border-color: transparent transparent #000 transparent;
            transform: rotate(-45deg);
            top: -20px;
            left: 0;
        }
    }

    @include breakpoint-min(md) {
        .htmlTooltip {
            min-height: 148px;
            .htmlButton {
                display: none;
            }
        }
    }

    .seat {
        transition: fill 0.5s ease 0s, stroke 0.5s ease 0s;
        fill-rule: evenodd;
        fill: $gray-400;
        z-index: 0;
        cursor: pointer;
        .active {
            fill: $clr-success;
        }
        .resale {
            fill: $clr-tertiary;
        }
        .available {
            fill: $secondary;
        }
        .accessible {
            fill: $clr-warning;
        }
        .unavailable {
            fill: $gray-400;
        }
    }
    .path {
        transition: opacity 0.5s ease 0s, fill 0.5s ease 0s;
        opacity: 1;
        stroke: white;
        stroke-width: 8px;
        fill: #3b81cb;
        z-index: 3;
        &.fillselected {
            fill: #101ad6;
        }
    }
    .expand {
        position: absolute;
        top: 20px;
        right: 19px;
        width: 40px;
        height: 40px;
        border: none;
        outline: none;
        background: rgba(255, 255, 255, 0.63);
        backdrop-filter: blur(2px);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        padding: 0;
        margin-top: 0;
        img {
            width: 24px;
            height: 24px;
        }
    }

    .controls-container {
        --right-btn-position: 20px;
        --left-btn-position: 10px;

        @include breakpoint-max(md) {
            &.with-ticket-popup {
                > * {
                    bottom: 171px;
                }
            }
        }

        > * {
            position: absolute;
            bottom: 20px;

            &:first-child {
                left: var(--left-btn-position);
            }

            &:last-child {
                right: var(--right-btn-position);
            }
        }

        .btn--filter {
            width: 159px;
        }
    }

    @include breakpoint-min(md) {
        .path:hover {
            fill: #101ad6;
        }
        .expand {
            display: none;
        }
    }

    .pathInvisible {
        opacity: 0;
        pointer-events: none;
    }
}
