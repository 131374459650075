@use '../../../../../scss/abstracts' as *;
@use '../../../../../scss/theme/custom' as *;
@use '../../../../../scss/layout' as *;

$hover-effect-box-shadow: inset 0 0 0 1px $primary;
$hover-effect-background: rgba($secondary, 0.05);

.left-col {
	@include breakpoint-min(md) {
		position: relative;
	}
	#ticket-selection-header {
		padding-bottom: rem(7);

		@include breakpoint-min(md) {
			padding-bottom: rem(21);
		}
	}

	.full-height-container {
		display: flex;
		flex-direction: column;
		flex: 1 1; // take up available space
		flex-basis: 0px; // starting height of 0px so does not stretch beyond the available space
		min-height: 0;
	}

	.selectedFakeTicket {
		background-color: $hover-effect-background;
		box-shadow: $hover-effect-box-shadow;
	}

	.ticket {
		background-color: $gray-300;
		padding: em(10) em(12);
		line-height: 1.2;
		border-radius: $border-radius-sm;
	}

	.tickets {
		margin-bottom: rem(15);

		.ticket:not(.seat-card) {
			user-select: none;
			cursor: pointer;

			&:hover,
			&:focus {
				background-color: $hover-effect-background;
				box-shadow: $hover-effect-box-shadow;
			}

			@include breakpoint-max(md) {
				// scroll effect only if eventType is seated and on mobile
				&.scrollSelected {
					background-color: $hover-effect-background;
					box-shadow: $hover-effect-box-shadow;
				}
			}
		}
	}

	.ticket-info-row {
		display: flex;
		align-items: center;
		justify-content: space-between;

		> * {
			min-width: 0;
		}
	}

	.ticket-name {
		font-weight: $fw-semi-bold;
		font-size: $small;
	}

	.ticket-price {
		flex-shrink: 0;
		text-align: right;
	}

	.total {
		font-size: $body-2;
		font-weight: $fw-semi-bold;
	}

	.tax-fees {
		font-size: $caption;
		color: $text-muted;
	}

	.ticket-limit {
		font-size: $caption;
		color: $text-muted;
		font-weight: $fw-normal;
	}

	.type {
		font-size: $small;
	}

	.btn-default {
		&,
		&:hover,
		&:focus {
			border: border($border-width: $btn-border-width);
		}
	}
}
