@use '../../../../../../scss/abstracts' as *;
@use '../../../../../../scss/layout/spacing' as *;
@use '../../../../../../scss/layout/images' as *;
@use '../../../../../../scss/theme/colors' as *;
@use '../../../../../../scss/theme/borders' as border;

#countdown {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: $gap;

	&::before {
		content: '';
		@extend %img-background;
		background-image: url($data-svg-prefix + map-get($icons, 'ticket'));
		background-color: rgba($primary, 0.1);
		background-size: 18px;
		width: 44px;
		height: 44px;
		border-radius: border.$border-radius-sm;
	}

	#countdown-timer {
		background-color: $gray-300;
		border-radius: border.$border-radius;
		padding: rem(12) 0px;
		width: 100%;
		font-size: $caption;

		.vstack {
			place-items: center;
			position: relative;
			flex: 0 0 81px;

			&:not(:last-child) {
				&::after {
					content: ':';
					position: absolute;
					right: -4px;
					top: 15%;
					font-size: $body-1;
					font-weight: $fw-bold;
				}
			}
		}

		.ticker {
			font-size: $h3-font-size;
			font-family: $font-heading;
			width: 100%;
			text-align: center;
		}
	}
}
