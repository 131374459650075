@use '../../scss/abstracts' as *;
@use '../../scss/layout/container' as *;
@use '../../scss/layout/spacing' as *;

.total-card.card.card--popup {
	@include breakpoint-max(md) {
		// keeps growing with its content until full page height
		bottom: 0;
		max-height: calc(100vh - $navbar-height-sm); // need this so element doesn't cover the entire page
	}

	.card-header {
		border-bottom-width: 0px;

		.card-title {
			font-size: rem(17);
		}

		.btn--icon-sm {
			border: none;

			&:hover,
			&:focus {
				background-color: transparent;
			}
			svg {
				transform: rotate(0deg);
			}
		}
	}

	.card-footer {
		display: flex;
		flex-direction: column;
		border-top-width: 0px;

		// keep sticky when content scrolls
		@include breakpoint-max(md) {
			position: sticky;
			bottom: 0;
		}

		> * {
			flex: 1;
		}
	}

	&-expanded {
		.card-header {
			.btn--icon-sm {
				svg {
					transform: rotate(180deg);
				}
			}
		}

		.card-body {
			border-top: border();
			padding-top: rem(20);
			margin-top: $text-spacer-xs;
		}

		.card-footer {
			border-top-width: 1px;
		}
	}
}
