@use '../abstracts' as *;
@use './buttons' as *;
@use './colors' as *;

$input-font-size: rem(14);
$input-font-weight: $fw-medium;
$input-font-size-sm: rem(12);
$input-font-size-lg: $input-font-size;
$input-disabled-bg: transparent;

// input
$input-padding-y: rem(12);
$input-padding-x: rem(16);

// hero search bar
$input-padding-y-lg: rem(20);
$input-padding-x-lg: rem(20);

// search input
$input-padding-x-sm: rem(16);
$input-padding-y-sm: rem(10);

$input-border-width: $border-width;
$input-focus-box-shadow: none;
$input-focus-border-color: $primary;
$input-placeholder-color: $clr-neutral-5;

$form-select-focus-box-shadow: none;
$form-select-padding-y: $btn-padding-y-lg;
$form-select-padding-x: $btn-padding-x-lg;
$form-select-bg-position: right 1em center;

$form-switch-color: $clr-neutral-4;
$form-switch-focus-color: $form-switch-color;
$form-switch-width: em(50);

$form-check-label-cursor: pointer;
$form-check-input-width: em(25);
$form-check-input-border: 2px solid $border-color;
$form-check-input-focus-border: $border-color;
$form-check-input-focus-box-shadow: none;
$form-label-font-size: rem(14);
$form-check-margin-bottom: rem(16);
