@use '../../../scss/abstracts' as *;
@use '../../../scss/layout/typography' as *;

#venue {
	.card {
		margin-top: -65px;
		box-shadow: 0px 40px 32px -24px rgba(15, 15, 15, 0.12);

		@include breakpoint-min(md) {
			margin-top: -133px;
		}

		&-body {
			padding: em(32);
			padding-bottom: 0px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			@include breakpoint-min(md) {
				padding: em(35) em(23);
			}

			.card-img {
				background-color: $clr-neutral-2;
				order: -3;
				width: 160px;
				height: 160px;
				margin-bottom: rem(25);
			}

			.card-title {
				font-weight: $fw-semi-bold;
				order: -2;
			}

			.card-text {
				order: -1;
				@include multi-line-truncate(5);
				margin-bottom: rem(28);
			}
		}
	}
}
