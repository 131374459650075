@use '../../../scss/abstracts' as *;

.flex {
	flex: 1;
	@include breakpoint-min(md) {
		gap: 21px;
	}
}
.purchaseTooltip {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 12px;
	gap: 10px;
	isolation: isolate;
	position: absolute;
	width: 117px;
	min-height: 48px;
	background: #23262f;
	box-shadow: 0px 4px 64px rgba(0, 0, 0, 0.11);
	border-radius: 10px;
	pointer-events: none;
	z-index: 10;
	&.hidden {
		display: none;
	}
	p {
		text-align: center;
		font-weight: 500;
		font-size: 14px;
		line-height: 24px;
		color: #fcfcfd;
		margin: 0;
	}
}
.purchaseTooltip::after {
	content: " ";
	position: absolute;
	top: 100%; /* At the bottom of the tooltip */
	left: 50%;
	margin-left: -10px;
	border-width: 10px;
	border-style: solid;
	border-color: #23262f transparent transparent transparent;
}
