@use "../../../scss/abstracts" as *;

.flipcardmodalbody {
    margin-top: 12px;
    overflow: scroll;
    p {
        font-family: $font-body;
        font-weight: 400;
        font-size: $body-2;
        line-height: 170%;
        color: #353945;
    }
}
