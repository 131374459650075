@use "../abstracts" as *;
@use "spacing" as *;
@use "typography" as *;
@use "container" as *;
@use "../theme/card" as *;
@use "../theme/colors" as *;

%plain-card {
    background: transparent;
    border-color: transparent;
    border-radius: 0px;

    .card-body {
        padding: em(21);

        & * {
            @extend %text-truncate !optional;
        }
    }

    .card-title {
        margin-bottom: em(10);
    }
}

.myCard,
.ticket-card {
    width: rem(256);
}

.card {
    &-subtitle {
        margin-top: 0px;
    }

    // feature card - homepage
    &--black {
        background-color: #000;
    }

    // white - get tickets flow
    // event details tickets, guest pass tickets
    &--white {
        background-color: #fff;
    }

    // feature cards
    &--dark-gray {
        background-color: #23262f;
    }

    // feature cards
    &--tertiary-light {
        background-color: #e9e2f2;

        &.frontY {
            .card-title {
                color: $clr-tertiary;
            }
        }
    }

    // off white - fcfcfd
    // feature cards - homepage
    // setting cards
    // venue card
    &--light {
        background-color: $white;

        .card-header,
        .card-footer {
            background-color: inherit;
        }
    }

    &--dark {
        background-color: $clr-neutral-3;
    }

    // feature and secondary cards - homepage
    &--darker {
        background-color: #141416;
    }

    // e6e8ec
    // add ons - ARE USING ?
    // feature and secondary cards - homepage
    &--muted {
        background-color: $light;
    }

    // feature and secondary cards - homepage
    &--pale {
        background-color: #e4d7cf;
    }

    // feature cards - homepage
    &--secondary {
        background-color: $secondary;
    }

    &--secondary,
    &--black,
    &--dark,
    &--darker,
    &--dark-gray,
    &--primary {
        .card-title,
        * {
            color: $white;
        }
    }

    // ticket purchase and total card popups
    &--popup {
        @include breakpoint-max(md) {
            @include full-width();
            border-bottom-right-radius: 0px;
            border-bottom-left-radius: 0px;
            box-shadow: $box-shadow-popupCard;
        }
    }

    .event-details {
        margin-bottom: rem(16);
        .event-detail {
            margin-bottom: rem(10);
        }
    }

    &-title {
        font-weight: $fw-medium;
        color: $body-color;
    }

    &-subtitle {
        font-weight: $fw-bold;
        font-size: $caption;
    }

    // used for secondary feature cards - homepage
    &-sm {
        border: none;
        border-radius: 21px;
        &.card-body {
            padding: em(32) rem(24);
        }

        .card-title {
            @extend .h4 !optional;
            font-weight: $fw-semi-bold;
        }
    }

    // used in addons, checkout ticket card
    &-xs {
        $padding: em(14) em(16);

        .card-body {
            padding: $padding;
        }
    }

    // used in settings options
    &-lg {
        $padding: em(30) em(20);

        .card-body {
            padding: $padding;
        }
    }

    // used in total and ticket purchase cards
    &-xl {
        $m-x: em(24);
        $pad-y: em(16);

        padding-left: 0px;
        padding-right: 0px;

        .card-header {
            padding: $pad-y 0px 0px;
            margin: 0px $m-x;
        }

        .card-heading {
            margin-bottom: $text-spacer-sm;
        }

        .card-title,
        .card-heading-title {
            font-size: $body-1;
            font-weight: $fw-semi-bold;
        }

        .card-body {
            padding: $pad-y 0px;
            margin: 0px $m-x;
            display: flex;
            flex-direction: column;

            &-header {
                padding-bottom: $pad-y;
            }
        }

        .card-footer {
            padding: 0px 0px $pad-y;
            margin: 0px $m-x;
        }
    }

    // used on feature cards in info wrapper
    &-xxl {
        border-radius: 30px;
        border: none;

        &.card-body {
            padding: rem(64) rem(44);

            @include breakpoint-min(lg) {
                padding-left: rem(44);
                padding-right: rem(44);
            }
        }

        .card {
            &-title {
                font-size: rem(36);
                font-weight: $fw-semi-bold;
                margin-bottom: 0px;

                @include breakpoint-min(md) {
                    font-size: rem(42);
                }

                @include breakpoint-min(xxl) {
                    font-size: rem(54);
                }

                &-sm {
                    @extend .h4 !optional;

                    @include breakpoint-max(lg) {
                        font-size: $body-1;
                    }
                }
            }

            &-subtitle {
                color: $text-muted;
                @extend .h4 !optional;
                font-weight: $fw-semi-bold;
                margin-top: em(5);

                &-sm {
                    font-size: $body-1;
                    font-weight: $fw-medium;
                }

                @include breakpoint-max(md) {
                    font-size: rem(18);
                    margin-top: rem(15);
                }

                @include breakpoint-inBetween(md, xxl) {
                    font-size: $body-1;
                    margin-top: rem(15);
                }
            }

            &-text {
                color: $text-muted;

                @include breakpoint-max(md) {
                    font-size: $small;
                }
            }
        }
    }
}
