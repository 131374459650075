@use '../../scss/abstracts' as *;
@use '../../scss/layout' as *;
@use '../../scss/theme/custom' as *;

#settings-container {
	@include breakpoint-min(md) {
		gap: 65px;
	}
	// .inner-container {
	// 	max-width: 424px;
	// }
}

.settings-heading {
	margin-bottom: $text-spacer-sm;
}

.settings-title {
	font-size: $body-1;
}

.settings-subtitle {
	font-size: $small;
	color: $muted;
	line-height: $line-height-base;
	font-weight: $fw-normal;
	margin: 0px;
}
