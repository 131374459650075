@use '../../../../../scss/abstracts' as *;
@use '../../../../../scss/layout/images' as *;
@use '../../../../../scss/theme/colors' as *;

#ticket-presale {
	&::before {
		background-image: url('../../../../../assets/icons/lock.svg');
		@extend %img-psuedo-icon;
		background-color: $gray-400;
		margin-bottom: rem(6);
		margin-right: auto;
		margin-left: auto;
	}

	#btn-unlock {
		width: 100%;
		height: 48px;
	}
}
