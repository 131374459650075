@use "../abstracts" as *;
@use "../layout/container" as *;
@use "../theme/colors" as *;
@use "../theme/borders" as *;

.badge {
    line-height: 20px;

    &-outline {
        text-transform: uppercase;
        border-style: solid;
        border-width: $border-width;
        background-color: $white !important;

        &.text-normal {
            text-transform: none;
        }

        &--primary {
            color: $primary;
            border-color: $primary;
        }

        &--light {
            border-radius: $br-secondary;
            border-color: $light;
            color: $body-color;
        }
    }

    // ticket card
    &-lg {
        @extend .normal !optional;
        @extend .normal-bold !optional;
        font-size: $body-2;
        border-radius: $br-primary;
        font-weight: $fw-medium;
        padding: rem(19);
    }

    &--tickets {
        @extend %icon-text-box !optional;
        justify-content: center;
        border-radius: 40px;
        font-size: $small;
        padding: rem(9) rem(16);
        &::before {
            content: url($data-svg-prefix + map-get($icons, "ticketOutline"));
            width: 18px;
            height: 18px;
            transform: scale(0.9);
        }

        @include breakpoint-min(md) {
            padding: rem(9) rem(36);
        }
    }
    // feature card - homepage
    &--clock {
        @extend %icon-text-box !optional;
        gap: rem(8);
        justify-content: center;
        border-radius: $border-radius;
        padding: rem(8) rem(12);
        &::before {
            content: url($data-svg-prefix + map-get($icons, "clock"));
        }
    }
}
