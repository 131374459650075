@use '../../../../../../scss/abstracts' as *;
@use '../../../../../../scss/theme/custom' as *;
@use '../../../../../../scss/layout' as *;

.seat-card {
	position: relative;
	width: calc(100% - 13px);
	margin-top: $text-spacer-xs;

	.btn--icon {
		position: absolute;
		right: -13px;
		top: -13px;
		width: 24px;
		height: 24px;
		background-image: url('../../../../../../assets/icons/close.svg');
		@extend %btn-background;
		background-color: #fff;
	}
	.seat-info {
		padding-bottom: rem(11);
		margin: 0px rem(35);

		&-block {
			&-label,
			&-value {
				display: block;
			}

			&-label {
				text-transform: uppercase;
				font-size: $caption;
				color: $gray-500;
				font-weight: $fw-bold;
				margin-bottom: rem(4);
			}

			&-value {
				font-size: $body-2;
				text-align: center;
				font-weight: $fw-semi-bold;
			}
		}
	}

	.ticket-info {
		border-top: border();
		padding-top: rem(10);
	}
}
